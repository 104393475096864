<template>
  <div>
    <h3>涨粉情况一览</h3>
    <div class="default-panel theme-border">
      <div class="left-panel" v-loading="loading">
        <div class="panel-row">
          <span class="title">年度：</span>
          <span>{{ year }} 年</span>
        </div>
        <div class="panel-row">
          <span slot="label" class="title">涨粉量：</span>
          <span>{{ $utils.numberFormat(statisticsData.year_trend_total, 0) }} </span>
        </div>
        <div class="panel-row">
          <span slot="label" class="title">有效账号：</span>
          <span>{{ $utils.numberFormat(statisticsData.vail_account_total, 0) }} </span>
        </div>
        <!--        <div>-->
        <!--          <span class="digit-uppercase">{{ $utils.digitUppercase(goi_money) }}</span>-->
        <!--        </div>-->
        <div class="panel-row">
          <el-checkbox v-model="checked">对比去年</el-checkbox>
        </div>
      </div>
      <div class="right-panel">
        <div class="right_panel_toolbar">
          <PlatformsRadioGroup :platform-code="searchCondition.platform_code" @choicePlatform="choicePlatform"
            :show-all="true" />
        </div>
        <echarts-chart :width="`100%`" :height="`300px`" :chart-option="chartOption" />
      </div>
    </div>
  </div>
</template>

<script>
import EchartsChart from '@/components/EchartsChart'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'

export default {
  name: 'DashboardTrend',
  components: {
    EchartsChart, PlatformsRadioGroup
  },
  data() {
    return {
      loading: false,
      chartOption: {},
      searchCondition: { year: null, platform_code: null },
      // barSeriesData: [],//柱状图数据
      // lineSeriesData: [],//折线图数据
      // vail_account_arr: [],
      statisticsData: {
        year_trend_total: 0,
        vail_account_total: 0,
      },
      checked: false
    }
  },
  props: {
    year: {
      type: String,
      required: true
    }
  },
  watch: {
    year() {
      this.searchCondition.year = this.year
      this.initData()
    },
    checked() {
      this.initData()
    }
  },
  mounted() {
    this.searchCondition.year = this.year
    this.searchCondition.platform_code = ''
    this.initData()
  },
  methods: {
    initData() {
      this.getStatistics()
      this.getChartData()
    },
    //年度数据统计
    async getStatistics() {
      this.loading = true

      let searchCondition = this.handleCondition()
      this.statisticsData = await this.$api.getStatisticsTrend(searchCondition);
      // this.loading = false
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    async getChartData() {
      let chartOption = {
        color: ['#ff3176'],
        title: {
          text: '涨粉情况一览',
          top: 0,
          left: '42%',
          textStyle: {
            color: '#303133',
            fontWeight: 'bold',
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          itemSize: '24',
          orient: 'vertical',
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['bar', 'line',] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: [],
          left: '60%'
        },
        xAxis: [
          {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              formatter: '{value}',
              show: true,
                color: '#ff3176',

            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '涨粉量',
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: '{value}',
              show: true,
                color: '#ff3176',

            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            },
          },
          {
            type: 'value',
            name: '有效账号',
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: '{value}',
              show: true,
                color: '#ff3176',

            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            },
          },

        ],
        series: []
      }

      let searchCondition = this.handleCondition()
      let { trend_arr, vail_account_arr } = await this.$api.getChartDataTrend(searchCondition);
      this.appendBarChart(chartOption, `${searchCondition.year}-涨粉量`, trend_arr)
      this.appendLineChart(chartOption, `${searchCondition.year}-有效账号`, vail_account_arr)

      if (this.checked) {
        searchCondition.year = searchCondition.year - 1
        let { trend_arr, vail_account_arr } = await this.$api.getChartDataTrend(searchCondition);
        this.appendBarChart(chartOption, `${searchCondition.year}-涨粉量`, trend_arr)
        this.appendLineChart(chartOption, `${searchCondition.year}-有效账号`, vail_account_arr)
      }

      // console.log('chartOption',chartOption)

      this.initChartData(chartOption)

    },
    appendBarChart(chartOption, title, data) {
      chartOption.legend.data.push(title)
      chartOption.series.push({
        name: title,
        type: 'bar',
        barWidth: 20,
        data: data,
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              textStyle: { //数值样式
                color: '#ff3176',
                fontSize: 12
              }
            },


      })
    },
    appendLineChart(chartOption, title, data) {
      chartOption.legend.data.push(title)
      chartOption.series.push({
        name: title,
        type: 'line',
        yAxisIndex: 1,
        data: data
      })
      return chartOption
    },
    initChartData(chartOption) {
      this.chartOption = chartOption
      // this.chartOption = {
      //   color: ['#ff3176'],
      //   title: {
      //     text: '涨粉情况一览',
      //     top: 0,
      //     left: 'left',
      //   },
      //   tooltip: {
      //     trigger: 'axis',
      //     axisPointer: {
      //       type: 'cross',
      //       crossStyle: {
      //         color: '#999'
      //       }
      //     }
      //   },
      //   toolbox: {
      //     feature: {
      //       dataView: { show: true, readOnly: false },
      //       magicType: { show: true, type: ['bar', 'line',] },
      //       restore: { show: true },
      //       saveAsImage: { show: true }
      //     }
      //   },
      //   legend: {
      //     data: ['涨粉量', '有效账号']
      //   },
      //   xAxis: [
      //     {
      //       type: 'category',
      //       data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      //       axisPointer: {
      //         type: 'shadow'
      //       }
      //     }
      //   ],
      //   yAxis: [
      //     {
      //       type: 'value',
      //       name: '涨粉量',
      //       // min: 0,
      //       // max: 25,
      //       // interval: 5,
      //       axisLabel: {
      //         formatter: '{value}'
      //       }
      //     },
      //     {
      //       type: 'value',
      //       name: '有效账号',
      //       // min: 0,
      //       // max: 250,
      //       // interval: 50,
      //       axisLabel: {
      //         formatter: '{value}'
      //       }
      //     },
      //
      //   ],
      //   series: [
      //     {
      //       name: '涨粉量',
      //       type: 'bar',
      //       barWidth: 20,
      //       data: this.barSeriesData,
      //       itemStyle: {
      //         normal: {
      //           label: {
      //             show: true, //开启显示
      //             position: 'top', //在上方显示
      //             textStyle: { //数值样式
      //               color: '#ff3176',
      //               fontSize: 12
      //             }
      //           },
      //         }
      //       }
      //     },
      //     {
      //       name: '有效账号',
      //       type: 'line',
      //       yAxisIndex: 1,
      //       data: this.lineSeriesData
      //     },
      //   ]
      // }
    },
    handleCondition() {
      let condition = {}
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }
      return condition
    },
    choicePlatform(val) {
      this.searchCondition.platform_code = val
      this.initData()
    }
  }
}
</script>

<style scoped>
.default-panel {
  display: flex;
  width: 100%;
  height: 350px;
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
}

.panel-row {
  margin-bottom: 20px;
}

.left-panel {
  flex: 3;
  /*border: 1px dashed #ff3176;*/
  padding: 10px 0 0 10px;
  margin: 10px 0 0 10px;
  /*border-right: 1px dashed rgba(255, 49, 118, 0.5);*/
}

.right-panel {
  flex: 9;
  padding: 5px;
  margin: 5px;
  /*border: 1px dashed #ff3176;*/
}

.title {
  font-size: 14px;
  font-weight: 700;
}

.digit-uppercase::before {
  content: '大写：';
}

.digit-uppercase {
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 5px;
  color: rgba(82, 82, 79, 0.8);
}
</style>

