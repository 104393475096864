<template>
  <div>
    <div style="margin-top: 10px;margin-bottom: 10px">
      <div style="display: inline"><span class="title">组织情况一览</span></div>
      <div style="display: inline;margin-left: 10px">
        <el-date-picker
            v-model="yearMonth"
            type="month"
            value-format="yyyy-MM"
            size="mini"
            placeholder="选择月">
        </el-date-picker>
        <el-link type="primary" :disabled="loading" :loading="loading" @click="getDeptList"
                 style="margin-left: 10px;font-size: 1rem">查询
        </el-link>
        &nbsp;&nbsp;
        <el-link type="primary" :disabled="loading" :loading="loading" @click="initData"
                 style="margin-left: 10px;font-size: 1rem"
                 :title="`点击更新,重新计算${this.searchCondition.year}年${this.searchCondition.month}月数据`"><i
            class="el-icon-refresh"></i>
          {{ loading ? '更新中...' : '' }}
        </el-link>
      </div>
    </div>
    <div class="default-panel theme-border">
      <el-row :gutter="5">
        <el-col :xl="8" :lg="8" :md="12" :sm="24" :xs="24">
          <div class="dept-table">
            <div class="default-table">
              <el-table
                  :data="deptTableData"
                  @row-click="deptRowEvent"
                  highlight-current-row
                  @sort-change="changeTableSort"
                  :summary-method="getSummaries"
                  show-summary
                  :row-class-name="tableRowClassName"
                  style="width: 100%">
                <el-table-column label="部门营收一览" align="center">
                  <el-table-column
                      prop="rank"
                      label="排名"
                      width="60" align="center">
                  </el-table-column>
                  <el-table-column
                      prop="dept_name"
                      label="部门"
                      min-width="100" align="center">
                  </el-table-column>
                  <el-table-column
                      prop="total_last_month"
                      label="上月营收"
                      min-width="100" align="right" header-align="center" sortable="custom">
                    <template slot-scope="scope">
                      <span class="money">{{ $utils.numberFormat(scope.row.total_last_month, 2) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="total_month"
                      label="本月营收"
                      min-width="100" align="right" header-align="center" sortable="custom">
                    <template slot-scope="scope">
                      <span class="money">{{ $utils.numberFormat(scope.row.total_month, 2) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="total_year"
                      label="全年营收"
                      min-width="100" align="right" header-align="center" sortable="custom">
                    <template slot-scope="scope">
                      <span class="money">{{ $utils.numberFormat(scope.row.total_year, 2) }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :xl="8" :lg="8" :md="12" :sm="24" :xs="24">
          <div class="group-table">
            <div class="default-table">
              <el-table
                  :data="groupTableData"
                  @row-click="groupRowEvent"
                  highlight-current-row
                  :summary-method="getSummaries"
                  @sort-change="changeGroupTableSort"
                  show-summary
                  style="width: 100%">
                <el-table-column :label="`小组营收一览(${currentDept.dept_name})`" align="center">
                  <el-table-column
                      prop="rank"
                      label="排名"
                      width="60" align="center">
                  </el-table-column>
                  <el-table-column
                      prop="dept_name"
                      label="部门"
                      min-width="100" align="center">
                  </el-table-column>
                  <el-table-column
                      prop="total_last_month"
                      label="上月营收"
                      min-width="100" align="right" header-align="center" sortable="custom">
                    <template slot-scope="scope">
                      <span class="money">{{ $utils.numberFormat(scope.row.total_last_month, 2) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="total_month"
                      label="本月营收"
                      min-width="100" align="right" header-align="center" sortable="custom">
                    <template slot-scope="scope">
                      <span class="money">{{ $utils.numberFormat(scope.row.total_month, 2) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="total_year"
                      label="全年营收"
                      min-width="100" align="right" header-align="center" sortable="custom">
                    <template slot-scope="scope">
                      <span class="money">{{ $utils.numberFormat(scope.row.total_year, 2) }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
        <el-col :xl="8" :lg="8" :md="12" :sm="24" :xs="24">
          <div class="member-table">
            <div class="default-table">
              <el-table
                  :data="memberTableData"
                  style="width: 100%">
                <el-table-column :label="`小组员工配置一览(${currentDept.dept_name})`" align="center">
                  <el-table-column
                      prop="dept_name"
                      label="组别"
                      min-width="80" align="center">
                  </el-table-column>
                  <el-table-column
                      prop="artist_total"
                      label="红人数"
                      min-width="100" align="center">
                  </el-table-column>
                  <el-table-column
                      prop="member_total"
                      label="员工数"
                      min-width="100" align="right" header-align="center">
                  </el-table-column>
                  <el-table-column
                      prop="perf_total"
                      label="绩效达成次数"
                      min-width="100" align="right" header-align="center">
                  </el-table-column>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!--    <div class="default-panel">-->
    <!--      <div class="left-panel">-->
    <!--        <el-form label-position="left" label-width="160px">-->
    <!--          <el-form-item label="年度：">-->
    <!--            <span slot="label" class="title">年度：</span>-->
    <!--            <span>{{ year }} 年</span>-->
    <!--          </el-form-item>-->
    <!--        </el-form>-->
    <!--      </div>-->
    <!--      <div class="right-panel">-->

    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import EchartsChart from "@/components/EchartsChart";
import PlatformsRadioGroup from "@/pages/platform/components/PlatformsRadioGroup"

export default {
  name: "DashboardDeptInfo",
  components: {
    EchartsChart, PlatformsRadioGroup
  },
  data() {
    return {
      deptTableData: [],
      groupTableData: [],
      memberTableData: [],
      currentDept: {},
      orderSort: { total_year: 'desc', id: 'asc' },
      yearMonth: null,
      searchCondition: { year: null, month: null },
      loading: false,
      autoUpdate: false,//标记已更新，避免重复更新，死循环的情况
    }
  },
  props: {
    year: {
      type: String,
      required: true
    }
  },
  watch: {
    year() {
      this.calcMonth()
    },
    yearMonth(val) {
      if (val) {
        let arr = val.indexOf('-') > -1 ? val.split('-') : []
        if (arr.length === 2) {
          this.autoUpdate = false
          this.searchCondition.year = arr[0]
          this.searchCondition.month = arr[1]
          this.getDeptList()
        }
      }
    }
  },
  methods: {
    async initData() {
      this.loading = true
      this.autoUpdate = true
      let { countDept, countGroup } = await this.$api.initStatisticsDept(this.searchCondition)
      let msg = `更新部门${ countDept } 个，项目组${ countGroup }个`
      this.$notify.success(msg)
      await this.getDeptList()
      this.loading = false
    },
    groupDetail() {
      this.getGroupList()
      this.getMember()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getDeptList()
    },
    changeGroupTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getGroupList()
    },
    async getDeptList() {
      this.deptTableData = []
      this.groupTableData = []
      this.memberTableData = []
      let searchCondition = { ...this.searchCondition }
      Object.assign(searchCondition, { order: this.orderSort })
      let { list } = await this.$api.getStatisticsDept(searchCondition)
      if (list) {
        this.deptTableData = list
        if (list.length > 0) {
          this.currentDept = list[0]
          await this.groupDetail()
        } else {
          if (!this.autoUpdate){
            // console.log(`autoUpdate:${this.autoUpdate}`)
            await this.initData()
          }

        }
      }
    },
    //项目组一览
    async getGroupList() {
      this.groupTableData = []
      let pid = this.currentDept.dept_id
      let searchCondition = { ...this.searchCondition, ...{ p_dept_id: pid } }
      Object.assign(searchCondition, { order: this.orderSort })
      let { list } = await this.$api.getStatisticsGroup(searchCondition)
      if (list) {
        //
        this.groupTableData = list
      }
    },
    //成员一览
    async getMember() {
      this.memberTableData = []
      let pid = this.currentDept.dept_id
      let searchCondition = { ...this.searchCondition, ...{ p_dept_id: pid } }
      // Object.assign(searchCondition)
      let { list } = await this.$api.getStatisticsMember(searchCondition)
      if (list) {
        this.memberTableData = list
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计：';
          return;
        }
        if (['total_month', 'total_year'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += ' ';
          } else {
            sums[index] = 'N/A';
          }

          switch (column.property) {
            case 'total_month':
            case 'total_year':
              sums[index] = '¥' + this.$utils.numberFormat(sums[index], 2)
              break
            default:
              // sums[index] = this.valFormat(sums[index])
              break
          }
        }
      });

      return sums;
    },
    // eslint-disable-next-line
    deptRowEvent(row, column, event) {
      this.currentDept = row
      this.groupDetail()
    },
    // eslint-disable-next-line
    groupRowEvent(row, column, event) {
      this.currentGroup = row
      // this.getGroupList(this.currentDept.dept_id)
    },
    changeMonth() {
      console.log(this.yearMonth)
    },
    calcMonth() {
      const end = new Date();
      this.yearMonth = this.year + '-' + (end.getMonth() + 1)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      if (row.dept_id == this.currentDept.dept_id) {
        return 'active-row handle-row';
      }
      return 'handle-row';
    }
  },
  mounted() {
    // this.searchCondition.year = this.year
    this.calcMonth()
  }
}
</script>

<style scoped>
.default-panel {
  /*display: flex;*/
  width: 100%;
  /*height: 350px;*/
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
}

.left-panel {
  flex: 2;
  /*border: 1px dashed #ff3176;*/
  padding: 10px;
  margin: 10px;
  border-right: 1px dashed rgba(255, 49, 118, 0.5);
}

.right-panel {
  flex: 10;
  padding: 10px;
  margin: 10px;
  /*border: 1px dashed #ff3176;*/
  display: flex;
}

.title {
  font-size: 16px;
  font-weight: 700;
}

.dept-table {
  margin-bottom: 10px;
  padding: 10px;
  /*flex: 4;*/
  /*padding-right: 20px;*/
  /*border-right: 1px dashed rgba(255, 49, 118, 0.5);*/
  /*margin: 10px;*/
}

.group-table {
  margin-bottom: 10px;
  padding: 10px;
  /*flex: 4;*/
  /*padding-right: 20px;*/
  /*border-right: 1px dashed rgba(255, 49, 118, 0.5);*/
  /*margin: 10px;*/
}

.member-table {
  margin-bottom: 10px;
  padding: 10px;
  /*flex: 4;*/
  /*border-right: 1px dashed rgba(255, 49, 118, 0.5);*/
  /*margin: 10px;*/
}
</style>

<style>

.el-table .active-row {
  /*background: rgba(255, 49, 118, 0.8);*/
  background: rgba(255, 233, 241, 0.9);
}

.el-table .handle-row {
  /*background: rgba(255, 49, 118, 0.8);*/
  cursor: pointer;
}
</style>
