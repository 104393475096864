<template>
  <div>
    <h3>电商销售额情况一览</h3>
    <div class="default-panel theme-border">
      <div class="left-panel">
        <div>
          <div class="panel-row">
            <span class="title">年度：</span>
            <span>{{ year }} 年</span>
          </div>
          <div class="panel-row">
            <span class="title" :title="`GMV (Gross Merchandise Volume) 订单金额， 包含付款和未付款的部分`">直播GMV：</span>
            <span>{{ $utils.numberFormat(live_gmv, 2) }} 元</span>
          </div>
          <div class="panel-row">
            <span slot="label" class="title">自营店GMV：</span>
            <span>{{ $utils.numberFormat(shop_gmv, 2) }} 元</span>
          </div>
          <div class="panel-row">
            <span slot="label" class="title"
                  :title="`GMV (Gross Merchandise Volume) 订单金额， 包含付款和未付款的部分`">电商本月(11月)总GMV：</span>
            <span>{{ $utils.numberFormat(bs_gmv_month, 2) }} 元</span>
          </div>
          <div class="panel-row">
            <span class="title">电商全年总GMV：</span>
            <span>{{ $utils.numberFormat(bs_gmv, 2) }} 元</span>
          </div>
          <div class="panel-row">
            <el-checkbox v-model="checked">对比去年</el-checkbox>
          </div>
        </div>
      </div>
      <div class="right-panel ">
        <div class="right_panel_toolbar">
          <el-radio-group v-model="searchCondition.bs_type" @change="getChartData">
            <el-radio-button label="all">电商汇总</el-radio-button>
            <el-radio-button label="live">直播</el-radio-button>
            <el-radio-button label="shop">店铺</el-radio-button>
          </el-radio-group>
        </div>
        <echarts-chart :width="`100%`" :height="`300px`" :chart-option="chartOption" />
      </div>
    </div>
  </div>
</template>

<script>
import EchartsChart from '@/components/EchartsChart'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'

export default {
  name: 'DashboardBs',
  components: {
    EchartsChart, PlatformsRadioGroup
  },
  data() {
    return {
      live_gmv: 0,
      shop_gmv: 0,
      bs_gmv_month: 0,
      bs_gmv: 0,
      chartOption: {},
      searchCondition: { year: null, bs_type: 'all' },
      order_total_arr: [],
      amount_total_arr: [],
      checked: false,
      yearRange: []
    }
  },
  props: {
    year: {
      type: String,
      required: true
    }
  },
  watch: {
    year() {
      this.searchCondition.year = this.year
      this.initData()
    },
    checked() {
      this.getChartData()
    }
  },
  mounted() {
    this.searchCondition.year = this.year
    this.initData()
  },
  methods: {
    handleCondition() {
      let condition = {}
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }

      if (this.searchCondition.bs_type) {
        condition.bs_type = this.searchCondition.bs_type
      }

      return condition
    },
    initData() {
      this.getStatistics()
      this.getChartData()
    },
    //年度数据统计
    async getStatistics() {
      this.loading = true
      this.shop_gmv = 0
      this.live_gmv = 0
      let searchCondition = this.handleCondition()
      let { live_gmv, shop_gmv, bs_gmv_month } = await this.$api.getStatisticsBs(searchCondition);
      this.live_gmv = live_gmv
      this.shop_gmv = shop_gmv
      this.bs_gmv_month = bs_gmv_month
      this.bs_gmv = live_gmv * 1 + shop_gmv * 1
      // this.loading = false
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    async getChartData() {
      let chartOption = {
        color: ['#ff3176'],
        title: {
          text: '电商销售额情况一览',
          top: 0,
          left: '30%',
          textStyle: {
            color: '#303133',
            fontWeight: 'bold',
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          itemSize: '24',
          orient: 'vertical',
          feature: {
            dataView: { show: true, readOnly: true },
            magicType: { show: true, type: ['bar', 'line'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: [],
          left: '60%'
        },
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            formatter: '{value}',
            show: true,
            color: '#ff3176',

          },
          nameTextStyle: {
            color: '#ff3176',
            padding: 10
          },
        },
        yAxis: {
          type: 'value',
          name: '成交金额（万元）',
          axisLabel: {
            formatter: '{value}',
            show: true,
            color: '#ff3176',

          },
          nameTextStyle: {
            color: '#ff3176',
            padding: 10
          },
        },
        series: [
          // {
          //   data: this.amount_total_arr,
          //   type: 'bar',
          //   barWidth: 20,
          //   showBackground: true,
          //   backgroundStyle: {
          //     color: 'rgba(180, 180, 180, 0.2)'
          //   },
          //   itemStyle: {
          //     normal: {
          //       label: {
          //         show: true, //开启显示
          //         position: 'top', //在上方显示
          //         textStyle: { //数值样式
          //           color: '#ff3176',
          //           fontSize: 12
          //         }
          //       },
          //     }
          //   }
          // }
        ]
      }
      let searchCondition = this.handleCondition()
      let { amount_total_arr } = await this.$api.getChartDataBs(searchCondition);
      this.amount_total_arr = amount_total_arr
      chartOption.legend.data.push(`${searchCondition.year}-成交金额`)
      chartOption.series.push({
        name: `${searchCondition.year}-成交金额`,
        data: this.amount_total_arr,
        type: 'bar',
        barWidth: 20,
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        },
        //标签：顶部显示柱状图数值
        label: {
          show: true, //开启显示
          position: 'top', //在上方显示
          textStyle: { //数值样式
            color: '#ff3176',
            fontSize: 12
          }
        },


      })

      if (this.checked) {
        searchCondition.year = searchCondition.year - 1
        let { amount_total_arr } = await this.$api.getChartDataBs(searchCondition);
        this.amount_total_arr = amount_total_arr
        chartOption.legend.data.push(`${searchCondition.year}-成交金额`)
        chartOption.series.push({
          name: `${searchCondition.year}-成交金额`,
          data: this.amount_total_arr,
          type: 'bar',
          barWidth: 20,
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            textStyle: { //数值样式
              color: '#ff3176',
              fontSize: 12
            }
          },


        })
      }

      this.initChartData(chartOption)

    },
    initChartData(chartOption) {
      this.chartOption = chartOption
    },
  }
}
</script>

<style scoped>
.default-panel {
  display: flex;
  width: 100%;
  /*height: 350px;*/
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
}

.panel-row {
  margin-bottom: 20px;
}

.left-panel {
  flex: 3;
  /*border: 1px dashed #ff3176;*/
  padding: 10px;
  margin: 10px;
  /*border-right: 1px dashed rgba(255, 49, 118, 0.5);*/
}

.right-panel {
  flex: 9;
  padding: 10px;
  margin: 10px;
  /*border: 1px dashed #ff3176;*/
}

.title {
  font-size: 14px;
  font-weight: 700;
}
</style>
