import JSEncrypt from 'jsencrypt/bin/jsencrypt'
import { parseTime } from '@/utils/index'
import common from '@/api/common'

const PemKey = 'pem'
// 密钥对生成 http://web.chacuo.net/netrsakeypair

// const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANL378k3RiZHWx5AfJqdH9xRNBmD9wGD\n' +
//   '2iRe41HdTNF8RUhNnHit5NpMNtGL0NPTSSpPjjI1kJfVorRvaQerUgkCAwEAAQ=='
// const privateKey = 'MIIBUwIBADANBgkqhkiG9w0BAQEFAASCAT0wggE5AgEAAkEA0vfvyTdGJkdbHkB8\n' +
//   'mp0f3FE0GYP3AYPaJF7jUd1M0XxFSE2ceK3k2kw20YvQ09NJKk+OMjWQl9WitG9p\n' +
//   'B6tSCQIDAQABAkA2SimBrWC2/wvauBuYqjCFwLvYiRYqZKThUS3MZlebXJiLB+Ue\n' +
//   '/gUifAAKIg1avttUZsHBHrop4qfJCwAI0+YRAiEA+W3NK/RaXtnRqmoUUkb59zsZ\n' +
//   'UBLpvZgQPfj1MhyHDz0CIQDYhsAhPJ3mgS64NbUZmGWuuNKp5coY2GIj/zYDMJp6\n' +
//   'vQIgUueLFXv/eZ1ekgz2Oi67MNCk5jeTF2BurZqNLR3MSmUCIFT3Q6uHMtsB9Eha\n' +
//   '4u7hS31tj1UWE+D+ADzp59MGnoftAiBeHT7gDMuqeJHPL4b+kC+gzV4FGTfhR9q3\n' +
//   'tTbklZkD2A=='
function getExpirationTime() {
  const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  const tomorrow_date = parseTime(tomorrow, '{y}-{m}-{d}')
  return new Date(`${tomorrow_date} 02:00:00`).getTime()
}

async function getPemPublicKey() {
  const pem_cache = localStorage.getItem(PemKey)
  const pem = JSON.parse(pem_cache)
  const currentTime = new Date().getTime()
  // 1. 缓存信息不存在
  // 2. 信息存在 但已过期
  // 3. 信息存在 过期时间不存在
  if ((pem && (pem.expiration_time < currentTime || !pem.expiration_time)) || !pem_cache) {
    const response = await common.getPubKey()
    if (response.success) {
      const content = JSON.stringify({
        data: response.info,
        expiration_time: getExpirationTime()
      })
      localStorage.setItem(PemKey, content)
    }
    return response.info
  } else {
    return pem.data
  }
}

// 加密
export async function pem(txt) {
  //获取公钥 await getPemPublicKey()
  let encryptor = new JSEncrypt()
  encryptor.setPublicKey(await getPemPublicKey())
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}
