<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content" style="max-width: 1400px;margin: auto">
        <el-row class="table-header">
        </el-row>
        <WsTag @wsOnMessage="wsOnMessage" @wsOnOpen="wsOnOpen" @wsOnClose="wsOnClose" ref="refWs" :url="wsUrl"></WsTag>
        <div style="padding: 10px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card class="box-card message-record">
                <div class="setting">
                  <div>
                    <el-divider content-position="left"> <span class="title">服务信息</span></el-divider>
                    <el-input placeholder="请输入内容" v-model="wsUrl" clearable>
                      <template slot="append">
                        <el-button type="primary" :class="`${!serverStarted?'link-wait':'link-done'}`" @click="startWs">
                          {{ !serverStarted ? ' 启动连接' : '关闭连接' }}
                        </el-button>
                      </template>
                    </el-input>
                  </div>
                  <div>
                    <el-divider content-position="left"> <span class="title">发包设置</span></el-divider>
                    <el-form ref="form" :model="formSend" label-position="left">
                      <el-form-item label="凭据(auth)" label-width="100px">
                        <el-input type="text" v-model="formSend.auth" placeholder="我的唯一标识"></el-input>
                      </el-form-item>
                      <el-form-item label="数据(data)" label-width="100px">
                        <el-input type="textarea"
                                  v-model="formSend.data"
                                  placeholder="需要发送到服务端的内容"
                                  :disabled="!serverStarted"
                                  rows="3"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="onSubmit" style="width: 100%" :disabled="!serverStarted">发送到服务端
                        </el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>

                <div class="debug-box">
                  <el-divider content-position="left">
                    <span class="title">调试消息</span>
                    &nbsp;&nbsp;
                    <el-link type="primary" @click="clearDebug">清空调试信息</el-link>
                  </el-divider>
                  <div class="debug-message">
                    <el-row v-for="(item,index) in debugList" :key="index" class="debug-row">
                      <el-col :span="24">
                        <span :class="`debug ${item.op==='OPENED'?'text-success':''}`">{{ item.time }} => </span>
                        <span class="debug" v-if="item.op">{{ item.op }} => </span>
                        <span>{{ item.msg }}</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-card>

            </el-col>
            <el-col :span="16">
              <el-card class="box-card message-record">
                <div>
                  <el-divider content-position="left">
                    <span class="title">消息记录</span>
                    &nbsp;&nbsp;
                    <el-link type="primary" @click="clearMessageRecord">清空消息</el-link>
                  </el-divider>
                </div>
                <div class="record">
                  <el-timeline :reverse="reverse">
                    <el-timeline-item :timestamp="`${item.time}`" placement="top" v-for="(item,index) in list"
                                      :key="index">
                      <el-card>
                        <h4>{{ item.title }}</h4>
                        <p>{{ item.msg }}</p>
                      </el-card>
                    </el-timeline-item>
                  </el-timeline>
                </div>

              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>

    </PageHeaderLayout>
  </div>
</template>

<script>
import WsTag from "@/components/WsTag";
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import {fixedZero} from "@/utils/utils";

export default {
  name: "WsIndex",
  components: {
    PageHeaderLayout,
    WsTag
  },
  data() {
    return {
      reverse: true,
      wsUrl: '',
      serverStarted: false,
      formSend: {
        data: '',
        auth: '',
      },
      list: [],
      debugList: []
    }
  },
  mounted() {
    this.wsUrl = process.env.VUE_APP_WS_URL
    if (localStorage.getItem('ws_address')) {
      this.wsUrl = localStorage.getItem('ws_address');
    }
  },
  methods: {
    wsOnOpen(msg) {
      this.msg = msg
      let time = this.getTime()
      if (typeof msg !== 'string') {
        msg = JSON.stringify(msg)
      }
      this.debugList.push({title: '调试消息', msg: msg, time: time, op: 'OPENED'})
    },

    wsOnClose(msg) {
      this.msg = msg
      let time = this.getTime()
      if (typeof msg !== 'string') {
        msg = JSON.stringify(msg)
      }
      this.debugList.push({title: '调试消息', msg: msg, time: time, op: 'CLOSED'})
    },

    wsOnError(msg) {
      let time = this.getTime()
      if (typeof msg !== 'string') {
        msg = JSON.stringify(msg)
      }
      this.debugList.push({title: '调试消息', msg: msg, time: time, op: 'ERROR'})
    },

    wsOnMessage(msg) {
      let time = this.getTime()
      this.list.push({title: '收到消息', msg: msg, time: time, op: 'RECV'})
    },
    onSubmit() {
      this.sendMessage(JSON.parse(JSON.stringify(this.formSend)))
    },
    sendMessage(msg) {
      let time = this.getTime()
      this.list.push({title: '发送消息', msg: msg, time: time, op: 'SEND'})
      this.$refs['refWs'].wsSend(msg)
    },
    getTime() {
      let date = new Date()
      return date.getHours() + ':' + fixedZero(date.getMinutes()) + ':' + fixedZero(date.getSeconds())
    },
    startWs() {
      if (!this.serverStarted) {
        localStorage.setItem('ws_address', this.wsUrl)
        this.$refs['refWs'].initWebSocket()
      } else {
        this.$refs['refWs'].wsClose()
      }
      this.serverStarted = !this.serverStarted
    },
    clearDebug() {
      this.debugList = []
    },
    clearMessageRecord() {
      this.list = []
    }
  }
}
</script>

<style scoped>
.title{
  font-weight: 800;
  font-size: 1.1rem;
  margin-right: 10px;
}
.box-card {
  height: 100%;
  padding: 5px;
}

.message-record {
  /*border: #ff3176 dashed 1px;*/
  min-height: 700px;
  max-height: 100vh;
}
.debug-row{
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-success {
  color: #28a745 !important;
}

.link-done {
  color: #FFFFFF !important;
  background-color: #f56c6c !important;
  border-color: #f56c6c !important;
  font-size: 14px !important;
  border-radius: 1px !important;
}

.link-wait {
  color: #FFFFFF !important;
  background-color: #ff3176 !important;
  border-color: #ff3176 !important;
  font-size: 14px !important;
  border-radius: 1px !important;
}

.setting{
  height: 350px;
}

.debug-box {

}
.debug-message{
  border: #ff3176 dashed 1px;
  padding: 10px;
  min-height: 240px;
  overflow-y: auto;
  height: 50vh;
  margin-bottom: 10px;
}

.record{
  border: #ff3176 dashed 1px;
  padding: 10px;
  min-height: 240px;
  overflow-y: auto;
  position: relative;
  height: 90vh;
}
</style>
