<template>
  <div class="auth-main" v-loading="loading">
    <el-row>
      <el-col>
        <!--                <a class=" button&#45;&#45;bird bird_box" href="#">-->
        <!--                  <div class="button__wrapper">-->
        <!--                  </div>-->
        <!--                  <div class="birdBox  password_bird">-->
        <!--                    <div class="bird wakeup_late">-->
        <!--                      <div class="bird__face"></div>-->
        <!--                    </div>-->
        <!--        &lt;!&ndash;            <div class="bird wakeup">&ndash;&gt;-->
        <!--        &lt;!&ndash;              <div class="bird__face"></div>&ndash;&gt;-->
        <!--        &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--                    <div class="bird wakeup">-->
        <!--                      <div class="bird__face"></div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </a>-->
        <div class="ape-top">
          <div class="ape-header">
            <router-link to="/">
              <img alt="缇苏" class="ape-logo" :src="logo"/>
              <!--						<span class="ape-title">缇苏</span>-->
            </router-link>
          </div>
          <div class="ape-desc">缇苏-星象仪</div>

        </div>
        <el-form v-if="!isScan" :model="loginData" :rules="rules" ref="loginForm" size="large" @submit.native.prevent>
          <el-form-item prop="account">
            <el-input clearable placeholder="账号/手机号"
                      v-model="loginData.account" prefix-icon="el-icon-user" @focus="userBtn"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input @keyup.enter.native="loginClick" clearable placeholder="密码"
                      prefix-icon="el-icon-key" type="password" @focus="pawBtn"
                      v-model="loginData.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button :disabled="canLogin" :loading="loginLoading" @click.stop.native="loginClick"
                       class="login-button"
                       id="login" type="primary">登 录
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-row>
              <el-col :span='8'>
                <el-checkbox v-model="loginData.rememberMe" style="margin:0 0 25px 0;">记住密码
                </el-checkbox>
              </el-col>

              <el-col :span='16' style='text-align: right'>
                <el-link v-if="isDingClient" :disabled='!isDingClient' icon='el-icon-connection' type="primary"
                         @click="dLoginByCode">钉钉授权登录
                </el-link>
                &nbsp;
                &nbsp;
                <el-link icon='el-icon-link' type="primary" @click="switchToH5">OA审批</el-link>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div v-else>
          <div class="backIcon" @click="isScan=false"><i class="el-icon-back"></i> 返回
          </div>
          <div id="login_container"></div>
        </div>
        <div>
          <el-divider>第三方登录</el-divider>
          <div class="other_login">
            <div title="钉钉快捷登录" @click="handleLogin('dingding')">
              <img :src="dingding">
            </div>
            <div title="钉钉扫码登录" @click="handleLogin('scan')">
              <img :src="scan">
            </div>
          </div>
        </div>

        <!--        <el-tabs :before-leave="beforeSwitch" v-model="activeName">-->
        <!--          <el-tab-pane label="账号密码登录" name="login1" style="color:black">-->
        <!--          </el-tab-pane>-->
        <!--          <el-tab-pane label="钉钉登录" name="login3">-->
        <!--            <div id="self_defined_element" class="self-defined-classname"></div>-->
        <!--            &lt;!&ndash;            <div id="login_container"></div>&ndash;&gt;-->
        <!--          </el-tab-pane>-->
        <!--          <el-tab-pane label="钉钉扫码登录" name="login2" v-if="!isDingClient">-->
        <!--            <div id="login_container"></div>-->
        <!--          </el-tab-pane>-->

        <!--        </el-tabs>-->
      </el-col>
    </el-row>
<!--    <el-row class="md8">-->
<!--      <el-col :span="24">-->
<!--        <el-button :disabled="loginLoading" :loading="loginLoading" @click="switchToH5" class="login-button"-->
<!--                   plain-->
<!--                   size="large"-->
<!--                   type="primary">H5应用-->
<!--        </el-button>-->
<!--      </el-col>-->
<!--    </el-row>-->
<!--    <el-row v-if="isDingClient">-->
<!--      <el-col :span="24">-->
<!--        <el-button :disabled="loginLoading" :loading="loginLoading" @click="dLoginByCode" class="login-button"-->
<!--                   plain-->
<!--                   size="large"-->
<!--                   type="primary">钉钉授权登录-->
<!--        </el-button>-->
<!--      </el-col>-->
<!--    </el-row>-->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getDingCode } from '@/utils/dingding'
import { DragCaptcha } from '@/pages/passport/dragCaptcha/dragCaptcha.js'
import '@/pages/passport/dragCaptcha/dragCaptcha.css'
import logo from '@/assets/festival/logo_sider-sd.png'
import ding from '@/assets/icons/dingding.png'
import scan from '@/assets/icons/scan.png'

const { decrypt } = require('@/utils/jsencrypt')

const { encrypt } = require('@/utils/jsencrypt')
const { pem } = require('@/utils/pem')

export default {
  name: 'Login',
  computed: {
    ...mapGetters(['userPermissions']),
    canLogin() {
      return this.loginData.account != '' && this.loginData.password != '' ? false : true
    }

  },
  data() {
    return {
      userAgent: '',
      activeName: 'login1',
      loginLoading: false,//登录状态
      loginData: { account: null, password: null, open_id: null, rememberMe: false },
      // 表单验证
      rules: {
        account: [
          { required: true, message: '输入账号手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '输入密码', trigger: 'blur' }
        ]
      },
      dialogBind: false,
      formLabelWidth: '120px',
      ding: {
        app_name: '星象仪-本地',
        app_redirect_uri: 'http://localhost:9100/login',
        app_id: ''
      },
      dingApp: { corp_id: '' },
      currentClientName: '',//当前客户端
      isDingClient: false,//当前客户端
      redirect: '/',
      otherQuery: {},
      btn: '',
      logo: logo,
      dingding: ding,
      scan: scan,
      isScan: false,
      loading:false
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations([
      'handleUserInfo'
    ]),
    toggleClass(ele, className) {
      if (!ele || !className) {
        return
      }
      let classString = ele.className
      const nameIndex = classString.indexOf(className)
      if (nameIndex === -1) {
        classString += ' ' + className
      }
      ele.className = classString
    },
    userBtn() {
      for (let i = 0; i < 3; i++) {
        this.toggleClass(document.getElementsByClassName('bird')[i], 'user_bird')
      }
    },
    pawBtn() {

      for (let i = 0; i < 3; i++) {
        let ele = document.getElementsByClassName('bird')[i]
        let className = 'user_bird'
        if (!ele || !className) {
          return false
        }
        let classString = ele.className
        const nameIndex = classString.indexOf(className)
        if (nameIndex > -1) {
          // classString = classString.replace(className, '')
          classString = classString.substr(0, nameIndex) +
              classString.substr(nameIndex + className.length)
        }
        ele.className = classString

      }
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    registerClick() {
      this.$message.error('功能暂未开放。')
    },
    // tab标签切换前调用,activeName, oldActiveNam
    // eslint-disable-next-line no-unused-vars
    async handleLogin(active) {
      if (active === 'scan') {
        this.isScan = true
        await this.getDing()
        this.dingLogin()
      }
      if (active === 'dingding') {
        await this.getDing()
        this.getUniteLogin()
      }
    },
    getCache() {
      const account = localStorage.getItem('account')
      const password = localStorage.getItem('password')
      const rememberMe = localStorage.getItem('rememberMe')
      this.loginData = {
        account: account === undefined ? this.loginData.account : account,
        password: password === undefined ? this.loginData.password : String(decrypt(password)),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      }
    },
    // 登录方式一：账号密码登录（点击登录按钮）
    loginClick() {

      let _this = this
      //4.设置debug  默认 true 会打印日志
      //5.设置验证成功 callback
      DragCaptcha.prototype.cbSuccess = function(drag) {
        _this.$refs['loginForm'].validate((valid) => {
          if (valid) {
            if (_this.loginData.rememberMe) {
              //缓存
              localStorage.setItem('account', _this.loginData.account)
              localStorage.setItem('password', encrypt(_this.loginData.password))
              localStorage.setItem('rememberMe', _this.loginData.rememberMe)
            } else {
              localStorage.removeItem('account')
              localStorage.removeItem('password')
              localStorage.removeItem('rememberMe')
            }
            _this.formSubmit()
          } else {
            _this.$message.error('登录失败，请检查必填项数据！')
          }
        })
        drag.addClickEventListener()
      }
      // DragCaptcha.prototype.cbFail = function() {
      //   // _this.$message.error('验证失败,请重试')
      //
      // }
      // 调用组件的数据验证方法
    },

    // 实际登录请求
    async formSubmit() {
      if (this.loginLoading !== false) {
        return
      }

      this.loginLoading = true
      setTimeout(() => {
        this.loginLoading = false
      }, 1000)
      let password = await pem(this.loginData.password)
      let loginData = {
        account: this.loginData.account,
        password: password,
        rememberMe: this.loginData.rememberMe
      }
      let { sid, userInfo } = await this.$api.submitLoginInfo(loginData)
      if (sid) {
        localStorage.setItem('HYPERF_SESSION_ID', sid)
      }
      this.loginLoading = false
      if (userInfo) {
        localStorage.setItem('user_info', JSON.stringify(userInfo))
        this.handleUserInfo(userInfo)
        // this.$notify.success('登录成功！')
        this.go()
      } else {
        this.initRespErrMsgBoxMark()
        this.$message.error('登录失败，请联系管理员！')
      }

    },
    // 路由跳转
    go() {
      this.loading = false
      // console.log(this.redirect)
      // console.log(this.otherQuery, 'other')
      if (this.redirect) {
        console.log(this.redirect)
        window.location.href = `${window.location.origin}${this.redirect}`
        // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    // 初始化响应错误弹窗标志
    initRespErrMsgBoxMark() {
      this.$store.commit('changeRespErrMsgBoxMark', false)
    },
    getQueryVariable(variable) {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }

      return false
    },
    // 登录方式二：钉钉扫码登录
    dingLogin() {
      this.$nextTick(() => {
        // const dingAppId = encodeURIComponent(process.env.VUE_APP_DING_APPID)
        const dingAppId = this.ding.app_id
        // const redirectUri = encodeURIComponent(process.env.VUE_APP_DING_REDIRECT_URI + '?dingLogin=Y')
        // const redirectUri = encodeURIComponent(window.location.origin + '?dingLogin=Y')
        const redirectUri = encodeURIComponent(window.location.href + '&dingLogin=Y')

        let goto = encodeURIComponent(`https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${dingAppId}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${redirectUri}`)

        /**
         * 解释一下goto参数，参考以下例子：
         * var url = encodeURIComponent('http://localhost.me/index.php?test=1&aa=2');
         * var goto = encodeURIComponent('https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=appid&response_type=code&scope=snsapi_login&state=STATE&redirect_uri='+url)
         */
        /* eslint-disable */
        DDLogin({
          id: 'login_container',//这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
          goto: goto, //请参考注释里的方式
          style: 'border:none;background-color:#f0f2f5;padding:0',
          width: '365',
          height: '300'
        })

        var handleMessage = function(event) {
          var origin = event.origin
          // console.log("origin", event.origin);
          if (origin == 'https://login.dingtalk.com') { //判断是否来自ddLogin扫码事件。
            const loginTmpCode = event.data
            //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
            // console.log("loginTmpCode", loginTmpCode);
            window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${dingAppId}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=REDIRECT_URI&loginTmpCode=${loginTmpCode}`
          }
        }
        if (typeof window.addEventListener != 'undefined') {
          window.addEventListener('message', handleMessage, false)
        } else if (typeof window.attachEvent != 'undefined') {
          window.attachEvent('onmessage', handleMessage)
        }

      })
    },

    // 处理钉钉-重定向回来后携带钉code和state
    async dingRedirectDeal() {
      // let dingLogin = this.getQueryVariable('dingLogin')
      let dingLogin = this.otherQuery['dingLogin']
      // let redirect= this.redirect
      // if (redirect) {
      //   let index = redirect.indexOf('dingLogin')
      //   dingLogin= index !== -1 ?'Y':'N'
      // }
      if (dingLogin && dingLogin === 'Y') {
        this.loading=true
        //是钉钉登录
        let code = this.getQueryVariable('code')
        let authCode = this.getQueryVariable('authCode')
        let state = this.getQueryVariable('state')
        if (authCode && state) {
          // 请求后端，认证用户
          let { sid, type, userInfo } = await this.$api.dingUniteLogin({ auth_code: code })
          if (sid) {
            localStorage.setItem('HYPERF_SESSION_ID', sid)
          }
          if (userInfo) {
            localStorage.setItem('user_info', JSON.stringify(userInfo))
            this.handleUserInfo(userInfo)
            // this.$message.success('登录成功！')
            // this.$notify.success('登录成功！')
            this.go()
          }
        } else if (code && state) {
          // 请求后端，认证用户
          let { sid, type, userInfo } = await this.$api.dingLogin({ code: code })
          if (sid) {
            localStorage.setItem('HYPERF_SESSION_ID', sid)
          }
          if (userInfo) {
            localStorage.setItem('user_info', JSON.stringify(userInfo))
            this.handleUserInfo(userInfo)
            // this.$message.success('登录成功！')
            // this.$notify.success('登录成功！')
            this.go()
          }
        } else {
          this.$message.error('钉钉验证失败')
        }
      }
    },

    //钉钉扫码登录配置信息
    async getDing() {
      let { ding } = await this.$api.getDingSetting()
      this.ding = ding
    },
    /**
     * 钉钉h5应用配置信息
     * @returns {Promise<void>}
     */
    async getDingApp() {
      let { dingApp } = await this.$api.getDingApp()
      this.dingApp = dingApp
    },
    //登录方式三：钉钉免登录授权码
    async dLoginByCode() {
      if (this.loginLoading !== false) {
        return
      }
      this.loginLoading = true
      // this.$notify.warning('免密登录中...')
      // this.$message.warning('免密登录中...')
      let _this = this
      let corpId = this.dingApp.corp_id
      getDingCode(corpId, (code) => {
        sessionStorage.setItem('isAutoLogin', '1')
        _this.dingLoginByJSCode(code)
      })
    },
    async dingLoginByJSCode(code) {
      let { sid, userInfo } = await this.$api.dingLoginByCode({ code: code })
      if (sid) {
        localStorage.setItem('HYPERF_SESSION_ID', sid)
      }
      if (userInfo) {
        localStorage.setItem('user_info', JSON.stringify(userInfo))
        this.handleUserInfo(userInfo)
        // this.$message.success('登录成功！')
        // this.$notify.success('登录成功！')
        this.go()
      } else {
        this.initRespErrMsgBoxMark()
        this.$message.error('登录失败，请联系管理员！')
      }

      this.loginLoading = false
    },
    switchToH5() {
      let host = window.location.host
      let protocol = window.location.protocol
      if (host.indexOf('pd') > -1) {
        host = host.replace('pd', 'pd-h5')
      } else {
        host = 'pd-h5-test.mulaitek.com'
      }
      let url = `${protocol}//${host}`
      this.$confirm(`即将跳转到审批页面：${url}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$notify.success(`即将跳转到审批页面：${url}`)
        window.location.href = url
        return false
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消跳转'
        })
      })
    }
    ,
    getUniteLogin() {
      const redirectUri = encodeURIComponent(window.location.href + '&dingLogin=Y')
      // const redirectUri = encodeURIComponent(window.location.origin + `?data=${this.redirect}&dingLogin=Y`)
      window.location.href = `https://login.dingtalk.com/oauth2/auth?redirect_uri=${redirectUri}&response_type=code&client_id=${this.ding.client_id}&scope=openid&state=dddd&prompt=consent`

    }

  },
  async mounted() {
    this.loginLoading = false
    await this.dingRedirectDeal()

    this.getCache()
    this.initRespErrMsgBoxMark()
    // 钉钉状态码处理
    // await this.getDingApp()
    const keyword = 'DingTalk'
    this.userAgent = navigator.userAgent
    if (navigator.userAgent.indexOf(keyword) > -1) {
      // 获取订单配置
      await this.getDingApp()

      this.currentClientName = keyword
      this.isDingClient = true
      let isAutoLogin = sessionStorage.getItem('isAutoLogin')
      if (isAutoLogin !== '1')
        this.dLoginByCode()
    }
    this.btn = document.getElementById('login')
    new DragCaptcha(this.btn)

    //1.设置 请求数据api 地址， 默认： /dragData
    DragCaptcha.prototype.apiDataUrl = '/admin_api/captcha/drag_data'
    //2.设置 请求验证api 地址， 默认： /dragVerify
    DragCaptcha.prototype.apiVerifyUrl = '/admin_api/captcha/grag_verfiry'
    //3.设置语言 默认 zh：中文， en:英文
    DragCaptcha.prototype.lang = 'zh'
    DragCaptcha.prototype.debug = false

  }
}
</script>

<style scoped>
.auth-main {
  width: 368px;
  margin: 0 auto;
  background-color: #ffffff8c;
  opacity: 0.8;
  border-radius: 5px;
  padding: 10px 20px;
}

.el-tabs__nav-wrap::after {
  height: 0
}

.el-tabs__nav {
  display: inline-block;
  float: none
}

.el-tabs__header {
  text-align: center
}

.el-input__prefix {
  left: 8px
}

.el-tabs__header {
  margin-bottom: 24px
}



.login-register {
  float: right;
  text-decoration: none;
  color: #1890ff;
}

.el-checkbox, .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(0, 0, 0, 0.65)
}

#tab-login2 {
  height: 0
}

.md8 {
  margin-bottom: 8px;
}

/deep/ .el-tabs__item.is-active {
  color: #f26946 !important;
}

/deep/ .el-tabs__active-bar {
  background-color: #f26946 !important;
}

/deep/ .el-tabs__item:hover {
  /*color: white !important;*/
}

/deep/ .el-form-item__error {
  color: white;
}
</style>
<style lang="scss" scoped>

.button--bird {
  --main_color: #f4cf47;
  --sub_color1: #f4e19c;
  --sub_color2: #ff8108;
  --base_color: #000;
  --border_radius1: 60px 60px 40px 40px / 48px 48px 30px 30px;
  --border_radius2: 70px 70px 40px 40px / 48px 48px 30px 30px;
  --border_radius3: 40px 40px 40px 40px / 48px 48px 30px 30px;
}

.user_bird::before {
  transform: translateX(-12px);
}

.bird_box:hover::before {
  right: 14px;
}

.birdBox {
  position: absolute;
  top: -24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100px;
  height: 56px;
  left: 220px;
}

.bird {
  position: relative;
  width: 56px;
  height: 36px;
  box-sizing: border-box;
  border: solid 3px #000;
  background: var(--main_color);
  border-radius: var(--border_radius1);
  animation: sleep 1s ease infinite alternate;
  display: flex;
  justify-content: center;
}

.bird__face {
  position: absolute;
  top: 15px;
  width: 12px;
  height: 6px;
  background: var(--sub_color2);
  border-radius: 50% 50% 50% 50% / 78% 78% 22% 22%;
  transition: .2s;
}

.bird__face::before,
.bird__face::after {
  content: '';
  position: absolute;
  top: -4px;
  width: 8px;
  height: 2px;
  border-radius: 4px;
  background: #000;
}

.bird__face::before {
  left: -5px;
}

.bird__face::after {
  right: -5px;
}

.bird::before {
  content: '';
  position: absolute;
  top: -12px;
  left: 22px;
  width: 12px;
  height: 12px;
  background: #000;
  clip-path: path('M10.23,3.32c-3.54,.63-5.72,2.51-7.02,4.23-.33-1.58-.34-3.54,.93-5.12,.52-.65,.41-1.59-.24-2.11C3.24-.19,2.29-.08,1.77,.57c-3.82,4.77-.31,11.11-.13,11.42,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0-.01-.02,2.49,.04,2.52,0,.1-.14,1.54-4.82,6.59-5.71,.82-.14,1.37-.92,1.22-1.74s-.94-1.36-1.75-1.21Z');
}

.user_bird {
  animation: wakeup .2s ease;
  animation-fill-mode: forwards;
}

.user_bird .wakeup {
  animation: wakeup .2s ease;
  animation-fill-mode: forwards;
}

.user_bird .wakeup_late {
  animation: wakeup .4s ease;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.user_bird .wakeup .bird__face {
  top: 20px;
}

.user_bird .wakeup_late .bird__face {
  animation-delay: 1s;
  top: 20px;
}

.user_bird .wakeup_late .bird__face::before,
.user_bird .wakeup_late .bird__face::after {
  animation: eye 5s linear infinite;
  animation-delay: .5s;
}

.user_bird .wakeup_late .bird__face::before,
.user_bird .wakeup_late .bird__face::after {
  animation: eye_2 5s linear infinite;
  animation-delay: .8s;

}

.user_bird .bird__face::before,
.user_bird .bird__face::after {
  animation: eye 5s linear infinite;
}

/*.bird_box:hover .wakeup:nth-child(2) .bird__face::before,*/
/*.bird_box:hover .wakeup:nth-child(2) .bird__face::after {*/
/*  animation: eye_2 5s linear infinite;*/
/*}*/

@keyframes wakeup {
  0% {
    height: 32px;
    border-radius: var(--border_radius2);
  }
  100% {
    height: 56px;
    border-radius: var(--border_radius3);
  }
}

@keyframes eye {
  0% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  30% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  32% {
    top: -4px;
    width: 8px;
    height: 2px;
  }
  34% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  70% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  72% {
    top: -4px;
    width: 8px;
    height: 2px;
  }
  74% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  76% {
    top: -4px;
    width: 8px;
    height: 2px;
  }
  78% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  100% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
}

@keyframes eye_2 {
  0% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  10% {
    transform: translateX(0);
  }
  12% {
    transform: translateX(3px);
  }
  20% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  22% {
    top: -4px;
    width: 8px;
    height: 2px;
  }
  24% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  25% {
    transform: translateX(3px);
  }
  27% {
    transform: translateX(0);
  }
  74% {
    top: -6px;
    width: 6px;
    height: 6px;
    transform: translateX(0);
  }
  76% {
    top: -4px;
    width: 8px;
    height: 2px;
    transform: translateX(3px);
  }
  78% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  80% {
    top: -4px;
    width: 8px;
    height: 2px;
  }
  82% {
    top: -6px;
    width: 6px;
    height: 6px;
  }
  85% {
    transform: translateX(3px);
  }
  87% {
    transform: translateX(0);
  }
  100% {
    top: -6px;
    width: 6px;
    height: 6px;
    transform: translateX(0);
  }

}

@keyframes sleep {
  0% {
    height: 36px;
    border-radius: var(--border_radius1);
  }
  100% {
    height: 32px;
    border-radius: var(--border_radius2);
  }
}

.self-defined-classname {
  width: 300px;
  height: 300px;
}

::v-deep .el-divider__text.is-center {
  background-color: #ffffff8c !important;
}


.other_login {
  margin-top: 20px;
  text-align: center;

  > div {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: inline-block;
    margin:0 10px;

    > img {
      width: 100%;
      height: 100%;
    }
  }
}

.backIcon {
  float: right;
  color: #ff3176;
  margin-right: 10px;
  margin-bottom: 4px;
  cursor: pointer;
}

</style>

