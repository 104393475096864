<template>
  <div>
    <PageHeaderLayout>

      <div class="main-page-content">
        <div style="padding: 5px 0">
          <el-form size='small' inline>
            <el-form-item label="年份：">
              <template slot="label"><span class="title" >年份：</span></template>
              <el-date-picker
                  v-model="year"
                  type="year"
                  value-format="yyyy"
                  format="yyyy年"
                  placeholder="选择年份">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div v-for="item in componentList" :key="item.Permissions">
          <!--            {{showData.includes(item.name)}}-->
          <keep-alive>

            <component v-if="userPermissions.indexOf(item.Permissions) !== -1&&item.isShow"
                       v-bind:is="item.name"
                       :id="item.name" :year="year"></component>
          </keep-alive>
        </div>

        <!--        &lt;!&ndash;    总营收情况一览&ndash;&gt;-->
        <!--        <DashboardRevenue :year="year" v-if="userPermissions.indexOf('manage_dashboard_revenue')>-1"/>-->
        <!--        &lt;!&ndash;    电商销售额情况一览&ndash;&gt;-->
        <!--        <DashboardBs :year="year" v-if="userPermissions.indexOf('manage_dashboard_bs')>-1"/>-->
        <!--        &lt;!&ndash;        涨粉一览&ndash;&gt;-->
        <!--        <DashboardTrend :year="year" v-if="userPermissions.indexOf('manage_dashboard_trend')>-1"/>-->
        <!--&lt;!&ndash;        推广支持情况一览&ndash;&gt;-->
        <!--        <DashboardPromotion :year="year" v-if="userPermissions.indexOf('manage_dashboard_promotion')>-1"/>-->
        <!--        &lt;!&ndash;    签约情况一览&ndash;&gt;-->
        <!--        <DashboardSignInfo :year="year" v-if="userPermissions.indexOf('manage_dashboard_sign')>-1"/>-->
        <!--        &lt;!&ndash;    组织情况一览&ndash;&gt;-->
        <!--        <DashboardDeptInfo :year="year" v-if="userPermissions.indexOf('manage_dashboard_dept')>-1"/>-->
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import DepartmentsChoice from '@/pages/groups/DepartmentsChoice'

import DashboardBs from '@/pages/dashboard/bs_data/DashboardBs'
import DashboardRevenue from '@/pages/dashboard/revenue/DashboardRevenue'
import DashboardSignInfo from '@/pages/dashboard/sign_info/DashboardSignInfo'
import DashboardDeptInfo from '@/pages/dashboard/deptment/DashboardDeptInfo'
import DashboardTrend from '@/pages/dashboard/trend/DashboardTrend'
import { mapGetters } from 'vuex'
import DashboardPromotion from '@/pages/dashboard/promotion/DashboardPromotion'

export default {
  name: 'DashBoardIndex',
  components: {
    DashboardPromotion,
    PageHeaderLayout,
    DepartmentsChoice,
    DashboardRevenue, DashboardBs, DashboardSignInfo, DashboardDeptInfo, DashboardTrend
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      activeName: 'second',
      year: null,
      componentList: [
        {
          name: 'DashboardRevenue',
          Permissions: 'manage_dashboard_revenue',
          isShow: true
        },
        {
          name: 'DashboardBs',
          Permissions: 'manage_dashboard_bs',
          isShow: true
        },
        {
          name: 'DashboardTrend',
          Permissions: 'manage_dashboard_trend',
          isShow: true
        },
        {
          name: 'DashboardPromotion',
          Permissions: 'manage_dashboard_promotion',
          isShow: true
        },
        {
          name: 'DashboardSignInfo',
          Permissions: 'manage_dashboard_sign',
          isShow: true
        },
        {
          name: 'DashboardDeptInfo',
          Permissions: 'manage_dashboard_dept',
          isShow: true
        }
      ]
    }
  },
  created() {
    // this.closeCollapse()
    this.year = (new Date()).getFullYear() + ''
  },
  methods: {}
}
</script>

<style scoped>
.title{
  font-weight: bold;
}
</style>
