<template>
  <div>
    <h3>总营收情况一览
      <el-link type='primary' icon='el-icon-refresh' @click='initData'></el-link>
    </h3>
    <div class='default-panel theme-border'>
      <div class='left-panel' v-loading='loading'>
        <h3>MCN全年</h3>
        <div class='panel-row'>
          <span class='title'>年度：</span>
          <span>{{ year }} 年</span>
        </div>
        <div class='panel-row'>
          <span class='title' slot='label'>执行订单数：</span>
          <span>{{ $utils.numberFormat(summaryData.order_total, 0) }} 单</span>
        </div>
        <div class='panel-row'>
          <span class='title' slot='label'>总营收：</span>
          <span>{{ $utils.numberFormat(summaryData.goi_money, 2) }} 元</span>
        </div>
        <div class='panel-row'>
          <span class='title' slot='label'>直播收入：</span>
          <span>{{ $utils.numberFormat(summaryData.live_total, 2) }} 元</span>
        </div>
        <div class='panel-row'>
          <span class='title' slot='label'>视频佣金：</span>
          <span>{{ $utils.numberFormat(summaryData.video_total, 2) }} 元</span>
        </div>
        <div class='panel-row'>
          <span class='title' slot='label'>商务营收：</span>
          <span>{{ $utils.numberFormat(summaryData.bs_total, 2) }} 元</span>
        </div>
        <div class='panel-row'>
          <el-checkbox v-model='checked'>对比去年</el-checkbox>
        </div>
      </div>
      <div class='right-panel'>
        <div class='right_panel_toolbar'>
          <PlatformsRadioGroup :platform-code='searchCondition.platform_code' :show-all='true'
                               @choicePlatform='choicePlatform' />
        </div>
        <echarts-chart :chart-option='chartOption' :width='`100%`' @chartSelect="selectKeyFun"/>
      </div>
    </div>
  </div>
</template>

<script>
import EchartsChart from '@/components/EchartsChart'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'

export default {
  name: 'DashboardRevenue',
  components: {
    EchartsChart, PlatformsRadioGroup
  },
  data() {
    return {
      loading: false,
      summaryData: {
        order_total: 0,
        goi_money: 0,
        live_total: 0,
        video_total: 0,
        bs_total: 0
      },

      chartOption: {},
      searchCondition: { year: null, platform_code: null },
      order_total_arr: [],
      revenue_total_arr: [],
      checked: false,
      yearRange: [],
      selectKey: {}
    }
  },
  props: {
    year: {
      type: String,
      required: true
    }
  },
  watch: {
    year() {
      this.searchCondition.year = this.year
      this.initData()
    },
    checked() {
      this.getChartDataRevenue()
    }
  },
  mounted() {
    this.searchCondition.year = this.year
    this.searchCondition.platform_code = ''
    this.initData()
  },
  methods: {
    initData() {
      this.getStatisticsRevenue()
      this.getChartDataRevenue()
    },
    //年度数据统计
    async getStatisticsRevenue() {
      this.loading = true
      this.order_total = 0
      this.goi_money = 0
      let searchCondition = this.handleCondition()
      this.summaryData = await this.$api.getStatisticsRevenue(searchCondition)

      // this.loading = false
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    //获取图表数据
    async getChartDataRevenue() {
      let chartOption = {
        color: ['#ff3176'],
        title: {
          text: '总营收情况一览',
          top: 0,
          left: '40%',
          textStyle:{
            color: '#303133',
            fontWeight: 'bold'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          orient: 'vertical',
          itemSize: '24',
          feature: {
            dataView: { show: true, readOnly: true },
            magicType: { show: true, type: ['bar', 'line'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: [],
          left: '60%',
          selected: {}//默认显示的echarts图
        },
        xAxis: [
          {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: { //轴文字标签
              show: true,
              color: '#ff3176'

            }

          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '金额（万元）',
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: '{value}',
              show: true,
              color: '#ff3176'

            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            }
          },
          {
            type: 'value',
            name: '数量（单）',
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: '{value}',
              show: true,
              color: '#ff3176'

            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            }
          }
        ],
        series: []
      }

      let searchCondition = this.handleCondition()
      let barWidth = 0
      let barGap = '10%'
      // 修改柱状图 顶部数值 排版
      //https://echarts.apache.org/examples/zh/editor.html?c=bar-label-rotation
      let labelOption = {
        show: true,
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'inside',
        distance: 15,
        // formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      }

      let chartData = await this.$api.getChartDataRevenue(searchCondition)
      this.chartSeriesAppend(chartOption, chartData, searchCondition.year, barWidth, barGap, labelOption)

      if (this.checked) {
        searchCondition.year = searchCondition.year - 1
        let chartData = await this.$api.getChartDataRevenue(searchCondition)
        this.chartSeriesAppend(chartOption, chartData, searchCondition.year, barWidth, barGap, labelOption)
      }

      this.initChartData(chartOption)
    },
    //图表-数据组装：追加
    chartSeriesAppend(chartOption, chartData, year, barWidth, barGap, labelOption) {
      chartOption.legend.data.push(`${year}-总营收`)
      chartOption.legend.data.push(`${year}-订单数`)
      chartOption.legend.data.push(`${year}-直播收入`)
      chartOption.legend.data.push(`${year}-视频佣金`)
      chartOption.legend.data.push(`${year}-商务营收`)
      // chartOption.legend.selected[`${year}-总营收`] = true
      // chartOption.legend.selected[`${year}-订单数`] = true
      // chartOption.legend.selected[`${year}-直播收入`] = false
      // chartOption.legend.selected[`${year}-视频佣金`] = false
      // chartOption.legend.selected[`${year}-商务营收`] = false
      if (JSON.stringify(this.selectKey) === '{}') {
        chartOption.legend.selected[`${year}-总营收`] = true
        chartOption.legend.selected[`${year}-订单数`] = true
        chartOption.legend.selected[`${year}-直播收入`] = false
        chartOption.legend.selected[`${year}-视频佣金`] = false
        chartOption.legend.selected[`${year}-商务营收`] = false

      } else {
        chartOption.legend.selected = this.selectKey
      }

      chartOption.series.push({
        name: `${year}-总营收`,
        type: 'bar',
        barWidth: barWidth,
        data: chartData.revenue_total_arr || [],
        label: {
          show: true, //开启显示
          position: 'top', //在上方显示
          color: '#ff3176',
          fontSize: 12
        },
        barGap: barGap
        // label: labelOption
      })
      chartOption.series.push({
        name: `${year}-订单数`,
        type: 'line',
        yAxisIndex: 1,
        data: chartData.order_total_arr || []
      })
      chartOption.series.push({
        name: `${year}-直播收入`,
        type: 'bar',
        barWidth: barWidth,
        data: chartData.live_total_arr || [],
        label: {
          show: true, //开启显示
          position: 'top', //在上方显示
          color: '#ff3176',
          fontSize: 12,
          labelOption
        },
        barGap: barGap
      }),
          chartOption.series.push({
            name: `${year}-商务营收`,
            type: 'bar',
            barWidth: barWidth,
            data: chartData.bs_total_arr || [],
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              color: '#ff3176',
              fontSize: 12,

              labelOption

            },
            barGap: barGap
          }),
          chartOption.series.push({
            name: `${year}-视频佣金`,
            type: 'bar',
            barWidth: barWidth,
            data: chartData.video_total_arr || [],
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              color: '#ff3176',
              fontSize: 12,

              labelOption

            },
            barGap: barGap
          })
    },
    //渲染echarts
    initChartData(chartOption) {
      this.chartOption = chartOption
    },
    handleCondition() {
      let condition = {}
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }
      return condition
    },
    choicePlatform(val) {
      this.searchCondition.platform_code = val
      this.initData()
    },
    compareChart() {
      //对比去年
      this.getChartDataRevenue()
    },
    selectKeyFun(data) {
      this.selectKey = data
    }
  }
}
</script>

<style scoped>
.default-panel {
  display: flex;
  width: 100%;
  flex-direction: row;
  /*height: 350px;*/
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
}

.panel-row {
  margin-bottom: 20px;
}

.left-panel {
  width: 300px;
  /*border: 1px dashed #ff3176;*/
  padding: 10px 0 0 10px;
  margin: 10px 0 0 10px;
  /*border-right: 1px dashed rgba(255, 49, 118, 0.5);*/
}

.right-panel {
  flex: auto;
  padding: 10px 0 0 10px;
  margin: 10px 0 0 10px;
  /*border: 1px dashed #ff3176;*/
}

.title {
  font-size: 14px;
  font-weight: 700;
}

.digit-uppercase::before {
  content: '大写：';
}

.digit-uppercase {
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 5px;
  color: rgba(82, 82, 79, 0.8);
}

</style>
