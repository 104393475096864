<template>
  <div>
    <h3>签约情况一览</h3>
    <div class="default-panel theme-border">
      <div class="left-panel" v-loading="loading">
        <div class="panel-row">
          <span class="title">年度：</span>
          <span>{{ year }} 年</span>
        </div>
        <div class="panel-row">
          <span slot="label" class="title">本月新签约红人数：</span>
          <span>{{ $utils.numberFormat(statisticsData.current_month_sign_total, 0) }} 人</span>
        </div>
        <div class="panel-row">
          <span slot="label" class="title">本月解约红人数：</span>
          <span>{{ $utils.numberFormat(statisticsData.current_month_fired_total, 0) }} 人</span>
        </div>
        <div class="panel-row">
          <span slot="label" class="title">截止目前在约红人数：</span>
          <span>{{ $utils.numberFormat(statisticsData.sign_total, 0) }} 人</span>
        </div>
        <div class="panel-row">
          <span slot="label" class="title">本年度新增红人数：</span>
          <span>{{ $utils.numberFormat(statisticsData.current_year_sign_total, 0) }} 人</span>
        </div>
        <div class="panel-row">
          <span slot="label" class="title">本年度总签约数：</span>
          <span>{{ $utils.numberFormat(statisticsData.current_year_signed_total, 0) }} 人</span>
        </div>
        <div class="panel-row">
          <span slot="label" class="title">本年度总解约数：</span>
          <span>{{ $utils.numberFormat(statisticsData.current_year_fired_total, 0) }} 人</span>
        </div>
        <div class="panel-row">
          <el-checkbox v-model="checked">对比去年</el-checkbox>
        </div>
      </div>
      <div class="right-panel">
        <echarts-chart :width="`100%`" :height="`300px`" :chart-option="chartOption" />
      </div>
    </div>
  </div>
</template>

<script>
import EchartsChart from "@/components/EchartsChart";
import PlatformsRadioGroup from "@/pages/platform/components/PlatformsRadioGroup"

export default {
  name: "DashboardSignInfo",
  components: {
    EchartsChart, PlatformsRadioGroup
  },
  data() {
    return {
      loading: false,
      statisticsData: {
        current_month_sign_total: 0,
        sign_total: 0,
        current_month_fired_total: 0,
        current_year_sign_total: 0,
        current_year_signed_total: 0,
        current_year_fired_total: 0,
      },
      chartOption: {},
      searchCondition: { year: null },
      fired_total_arr: [],
      sign_total_arr: [],
      checked: false,

    }
  },
  props: {
    year: {
      type: String,
      required: true
    }
  },
  watch: {
    year() {
      this.searchCondition.year = this.year
      this.initData()
    },
    checked() {
      this.initData()
    }
  },
  mounted() {
    this.searchCondition.year = this.year
    this.initData()
  },
  methods: {
    handleCondition() {
      let condition = {}
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }

      return condition
    },
    initData() {
      this.getStatistics()
      this.getChartData()
    },
    //年度数据统计
    async getStatistics() {
      this.loading = true

      let searchCondition = this.handleCondition()
      this.statisticsData = await this.$api.getStatisticsSign(searchCondition);
      // this.statisticsData = statisticsData
      // this.loading = false
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    async getChartData() {
      let chartOption = {
        color: ['#ff3176'],
        title: {
          text: '签约情况一览',
          top: 0,
          left: '45%',
          textStyle: {
            color: '#303133',
            fontWeight: 'bold',
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          itemSize: '24',
          orient: 'vertical',
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['bar', 'line',] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: [],
          left: '60%',

        },
        xAxis: [
          {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              formatter: '{value}',
              show: true,
                color: '#ff3176',

            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '签约（人）',
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              formatter: '{value}',
              show: true,
                color: '#ff3176',

            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            },
          },
          {
            type: 'value',
            name: '解约（人）',
            // min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: '{value}',
              show: true,
                color: '#ff3176',

            },
            nameTextStyle: {
              color: '#ff3176',
              padding: 10
            },
          },
        ],
        series: [
          // {
          //   name: '签约',
          //   type: 'bar',
          //   barWidth: 20,
          //   data: this.sign_total_arr
          // },
          // {
          //   name: '解约',
          //   type: 'line',
          //   yAxisIndex: 1,
          //   data: this.fired_total_arr
          // },

        ]
      }
      let searchCondition = this.handleCondition()
      let { fired_total_arr, sign_total_arr } = await this.$api.getChartDataSign(searchCondition);
      this.appendBarChart(chartOption, `${searchCondition.year}-签约`, sign_total_arr)
      this.appendLineChart(chartOption, `${searchCondition.year}-解约`, fired_total_arr)

      if (this.checked) {
        searchCondition.year = searchCondition.year - 1
        let { fired_total_arr, sign_total_arr } = await this.$api.getChartDataSign(searchCondition);
        this.appendBarChart(chartOption, `${searchCondition.year}-签约`, sign_total_arr)
        this.appendLineChart(chartOption, `${searchCondition.year}-解约`, fired_total_arr)
      }

      this.initChartData(chartOption)
    },
    appendBarChart(chartOption, title, data) {
      chartOption.legend.data.push(title)
      chartOption.series.push({
        name: title,
        type: 'bar',
        barWidth: 20,
        data: data,
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              textStyle: { //数值样式
                color: '#ff3176',
                fontSize: 12
              }
            },


      })
    },
    appendLineChart(chartOption, title, data) {
      chartOption.legend.data.push(title)
      chartOption.series.push({
        name: title,
        type: 'line',
        yAxisIndex: 1,
        data: data
      })
      return chartOption
    },
    initChartData(chartOption) {
      this.chartOption = chartOption
    },
    // initChartData() {
    //   this.chartOption = {
    //     color: ['#ff3176'],
    //     title: {
    //       text: '签约情况一览',
    //       top: 0,
    //       left: 'left'
    //     },
    //     tooltip: {
    //       trigger: 'axis',
    //       axisPointer: {
    //         type: 'cross',
    //         crossStyle: {
    //           color: '#999'
    //         }
    //       }
    //     },
    //     toolbox: {
    //       feature: {
    //         dataView: { show: true, readOnly: false },
    //         magicType: { show: true, type: ['bar', 'line',] },
    //         restore: { show: true },
    //         saveAsImage: { show: true }
    //       }
    //     },
    //     legend: {
    //       data: ['签约', '解约']
    //     },
    //     xAxis: [
    //       {
    //         type: 'category',
    //         data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    //         axisPointer: {
    //           type: 'shadow'
    //         }
    //       }
    //     ],
    //     yAxis: [
    //       {
    //         type: 'value',
    //         name: '签约（人）',
    //         // min: 0,
    //         // max: 250,
    //         // interval: 50,
    //         axisLabel: {
    //           formatter: '{value}'
    //         }
    //       },
    //       {
    //         type: 'value',
    //         name: '解约（人）',
    //         // min: 0,
    //         // max: 25,
    //         // interval: 5,
    //         axisLabel: {
    //           formatter: '{value}'
    //         }
    //       },
    //     ],
    //     series: [
    //       {
    //         name: '签约',
    //         type: 'bar',
    //         barWidth: 20,
    //         data: this.sign_total_arr
    //       },
    //       {
    //         name: '解约',
    //         type: 'line',
    //         yAxisIndex: 1,
    //         data: this.fired_total_arr
    //       },
    //
    //     ]
    //   }
    // },
  }
}
</script>

<style scoped>
.default-panel {
  display: flex;
  width: 100%;
  height: 350px;
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
}

.panel-row {
  margin-bottom: 20px;
}

.left-panel {
  flex: 3;
  /*border: 1px dashed #ff3176;*/
  padding: 10px;
  margin: 10px;
  /*border-right: 1px dashed rgba(255, 49, 118, 0.5);*/
}

.right-panel {
  flex: 9;
  padding: 10px;
  margin: 10px;
  /*border: 1px dashed #ff3176;*/
}

.title {
  font-size: 14px;
  font-weight: 700;
}
</style>
